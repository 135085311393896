<template>
   <v-card class="ma-2" :height="height">
    <v-layout>
      <v-flex lg2>
        <v-navigation-drawer
          v-model="drawer" :mini-variant.sync="mini" permanent :height="height - 17"
          :color="($vuetify.theme.dark) ? '' : ''"
        >
          <v-list class="pa-2">
            <v-list-item-group v-model="selectedItem" color="primary" mandatory>
              <v-list-item v-for="(item, i) in menuItems" :key="i" class="px-5 py-6">
                <v-icon v-text="item.icon" size="21" left></v-icon>
                <span class="body-2"> {{ item.title }} </span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex>
        <v-card :max-height="height - 17" class="overflow-y-auto" flat>
          <v-form ref="form">
            <v-card-text v-if="selectedItem === 0">
              Inbox Items
              <v-divider></v-divider>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-spacer></v-spacer>
      <v-flex xs2 class="mt-n8 d-flex justify-end mr-5">
        <v-btn color="primary" fab @click="sendMailDialog = true">
          <v-icon>mdi-email-edit</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog v-model="sendMailDialog" scrollable max-width="700px">
      <v-card>
        <v-card-title>Compose</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-row class="mt-3">
            <v-col cols="12">
              <v-combobox label="Recepients" outlined dense hide-details></v-combobox>
            </v-col>
            <v-col cols="12">
              <editor v-model="sendMailObj.body" ref="editor"> </editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="blue darken-1" text @click="sendMail()"> Send </v-btn>
          <v-btn color="blue darken-1" text @click="sendMailDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      sendMailObj: {},
      sendMailDialog: false,
      height: window.innerHeight - 77,
      drawer: false,
      selectedItem: 0,
      menuItems: [{
        title: 'Inbox',
        icon: 'mdi-home-city'
      }, {
        title: 'Drafts',
        icon: 'mdi-calendar'
      }, {
        title: 'Sent items',
        icon: 'mdi-calendar'
      }, {
        title: 'Templates',
        icon: 'mdi-timelapse'
      }, {
        title: 'History / Reports',
        icon: 'mdi-calendar-week',
      }],
      mini: false,
    }
  },
  components: {
    'editor': () => import('./Editor.vue')
  },
  methods: {
    sendMail () {
      console.log(this.$refs.editor.getBodyContent())
    }
  }
}
</script>

<style>

</style>